import React, { createContext } from 'react';
import useLocalStorage from '../../glaCalculator/hooks/useLocalStorage';

const GLACalculatorContext = createContext({
    GLACalculatorData: {
        adminCharges: {
            commission: 25,
            expense: 5,
            profit: 2.5
        }
    },
    coverTypeListData: [{
        coverType: 'GLA',
        maas: 3
    }],
    discountFieldOpen: false,
    discount: null,
    complimentaryBenefitModalOpen: false,
    complimentaryBenefitListData: [],
    updateDiscount: () => { },
    updateGLACalculatorData: () => { },
    updateCoverTypeList: () => { },
    addNewCoverTypeToList: () => { },
    removeCoverTypeFromList: () => { },
    openDiscountField: () => { },
    closeDiscountField: () => { },
    applyDiscount: () => { },
    updateComplimentaryBenefitList: () => { },
    addNewComplimentaryBenefitToList: () => { },
    removeComplimentaryBenefitFromList: () => { },
    toggleComplimentaryBenefitDrawer: () => { },
    resetComplimentaryBenefitList: () => { }
});

const GLACalculatorProvider = ({ children }) => {
    const [GLACalculatorData, setGLACalculatorData] = useLocalStorage('GLACalculatorData', {
        adminCharges: {
            commission: 25,
            expense: 5,
            profit: 2.5
        }

    });
    const [coverTypeListData, setCoverTypeListData] = useLocalStorage('coverTypeListData', [{
        coverType: 'GLA',
        maas: 3
    }]);
    const [discount, setDiscount] = useLocalStorage('discount', null);
    const [discountFieldOpen, setDiscountFieldOpen] = useLocalStorage('discountFieldOpen', false);
    const [complimentaryBenefitModalOpen, setComplementaryBenefitModalOpen] = useLocalStorage('complimentaryBenefitModalOpen', false);
    const [complimentaryBenefitListData, setComplimentaryBenefitListData] = useLocalStorage('complimentaryBenefitListData', []);

    const updateGLACalculatorData = (obj, page) => {
        setGLACalculatorData((prevData) => ({
            ...prevData,
            [page]: { ...prevData[page], ...obj },
        }));
        console.log('gla data ->', GLACalculatorData)
    };

    const resetComplimentaryBenefitList = () => {
        setComplimentaryBenefitListData([])
        setComplementaryBenefitModalOpen(false)
    }

    // const updateDefaultValue = (newList, index)=>{
    //     switch (newList[index]["coverType"]) {
    //       case 'ACC DEATH':
    //         newList[index]["maas"] = 2;
    //         break;
    //       case 'TEMP DISABILITY':
    //         newList[index]["maas"]  = 1;
    //         break;
    //       case 'PERM DISABILITY':
    //         newList[index]["maas"] = 3;
    //         break;
    //       case 'MEDICAL COVER':
    //         newList[index]["maas"] = 0.1;
    //         break;
    //       case 'CRITICAL ILLNESS':
    //         newList[index]["maas"] = 3;
    //         break;
    //       default:
    //         newList[index]["mass"] = value;
    //         break;
    //     }
    //   }
  
    const updateCoverTypeList = (index, name, value) => {
        const newList = [...coverTypeListData]
        newList[index][name] = value;
        setCoverTypeListData(newList)
        console.log('cover types ->', coverTypeListData)
    }

    const addNewCoverTypeToList = () => {
        setCoverTypeListData([...coverTypeListData, {}])
        console.log('cover types ->', coverTypeListData)
    }

    const removeCoverTypeFromList = (index) => {
        const newList = [...coverTypeListData]
        newList.splice(index, 1)
        setCoverTypeListData(newList)
        console.log('cover types ->', coverTypeListData)
    }

    const updateComplimentaryBenefitList = (index, name, value) => {
        const newList = [...complimentaryBenefitListData]
        newList[index][name] = value;
        setComplimentaryBenefitListData(newList)
        console.log('com bene ->', complimentaryBenefitListData)
    }

    const addNewComplimentaryBenefitToList = () => {
        setComplimentaryBenefitListData([...complimentaryBenefitListData, {}])
        console.log('com bene ->', complimentaryBenefitListData)
    }

    const removeComplimentaryBenefitFromList = (index) => {
        const newList = [...complimentaryBenefitListData]
        newList.splice(index, 1)
        setComplimentaryBenefitListData(newList)
        console.log('com bene ->', complimentaryBenefitListData)
    }
    const openDiscountField = () => {
        setDiscountFieldOpen(true)
    }
    const closeDiscountField = () => {
        setDiscountFieldOpen(false)
    }

    const updateDiscount = (value) => {
        setDiscount(value)
    }
    const applyDiscount = () => {
        updateDiscount(discount)
        closeDiscountField();
    }

    const toggleComplimentaryBenefitDrawer = () => {
        setComplementaryBenefitModalOpen((prevState) => !prevState);
    };
    return (
        <GLACalculatorContext.Provider
            value={{
                GLACalculatorData,
                coverTypeListData,
                discountFieldOpen,
                discount,
                complimentaryBenefitModalOpen,
                complimentaryBenefitListData,
                updateDiscount,
                applyDiscount,
                openDiscountField,
                closeDiscountField,
                updateGLACalculatorData,
                updateCoverTypeList,
                addNewCoverTypeToList,
                removeCoverTypeFromList,
                updateComplimentaryBenefitList,
                addNewComplimentaryBenefitToList,
                removeComplimentaryBenefitFromList,
                toggleComplimentaryBenefitDrawer,
                resetComplimentaryBenefitList
            }}
        >
            {children}
        </GLACalculatorContext.Provider>
    );
};

export { GLACalculatorContext, GLACalculatorProvider };
