import React from "react";
import { navigate } from "@reach/router";
import {
  OmApplicationWizardHeader,
  OmIcon,
} from "@om/component-library-react";
import OmButton from "../../../../../components/forms/OmButton";


const ApplicationWizardHeader = ({ callMeBack }) => {
  const closeHander = () => {
    if (typeof window !== "undefined") {
      localStorage.clear();
    }
    navigate("/");
  };

  return (
    <OmApplicationWizardHeader
      helpNumber="+233 (0) 501 533 745 or 0307 000 600"
      className="banner-image"
    >
      <div slot="wizard-title">
        <h5>
          <strong>GLA Calculator</strong>
        </h5>
      </div>

      <span slot="contact-button">
        <OmButton
          type="secondary"
          actionType="Call Me Back"
          size="small"
          text="Call me back"
          callMeBackObject={callMeBack ? JSON.stringify(callMeBack) : ""}
        />
      </span>
      <a slot="close-button">
        <OmIcon
          className="close-icon"
          iconName="close"
          onClick={() => closeHander()}
        />
      </a>
    </OmApplicationWizardHeader>
  );
};

export default ApplicationWizardHeader;
