import React from "react";
import { OmApplicationPage } from "@om/component-library-react";
import ApplicationLayout from "../../../../layouts/ApplicationLayout";
import ApplicationWizardHeader from "../applications/glaCalculator/ApplicationWizardHeader";
import { GLACalculatorProvider } from "../applications/glaCalculator/context/GLACalculatorContext";
import Outlet from "../applications/glaCalculator/pages/Outlet";
const GLACalculatorPage = () => {
  return (
    <GLACalculatorProvider>
      <ApplicationLayout>
        <OmApplicationPage>
          <div slot="header">
            <ApplicationWizardHeader callMeBack={{}} />
          </div>
          <div slot="content-no-sidebar">
            <Outlet />
          </div>
        </OmApplicationPage>
      </ApplicationLayout>
    </GLACalculatorProvider>
  );
};

export default GLACalculatorPage;
