import React, { useContext, useState } from 'react';
import { navigate } from '@reach/router'
import { DEFAULT_VALIDATION_DELAY } from '../../../../../ghana/configs/gla-calculator-configs/config';
import {
    OmFormDropdownFieldWrapper,
    OmFormInputFieldWrapper,
    OmApplicationButton
} from '@om/component-library-react';
import { GLACalculatorContext } from '../../../glaCalculator/context/GLACalculatorContext';
import {
    formatGLAPayload,
    isInputValid, isMutipleRangeValid,
} from '../../../../../ghana/utils/gla-calculator-utils/util';
import './MainPage.scss';
import {
    OmdsCell,
    OmdsGrid, OmdsIcon
} from '@mom-omds/react-design-system-components/dist/generated/components';
import OmButton from '../../../../../../../components/forms/OmButton';
import { COVER_TYPES, HOSPITAL_CASH } from '../../constants/refeferenceData/glaCalculatorLevel';
import ComplimentaryBenefitSideDrawer from './ComplimentaryBenefitSideDrawer';


const MainPage = () => {
    const { GLACalculatorData, coverTypeListData, discountFieldOpen, discount, complimentaryBenefitListData, toggleComplimentaryBenefitDrawer, updateDiscount, applyDiscount, openDiscountField, closeDiscountField, updateGLACalculatorData, addNewCoverTypeToList, removeCoverTypeFromList, updateCoverTypeList } = useContext(GLACalculatorContext);
    const [annualPremium, setAnnualPremium] = useState(null)
    const [monthlyPremium, setMonthlyPremium] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)

    const calculatePremium = async () => {
        const _data = JSON.parse(localStorage.getItem('GLACalculatorData'));
        const _cover_type_list_data = JSON.parse(localStorage.getItem('coverTypeListData'));
        const url = process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payment.oldmutual.com.gh/api/v1/calculated-premium' : 'https://test-payment.oldmutual.com.gh/api/v1/calculated-premium';
        const calculatePremiumPayload = {
            'averageAge': _data?.averageSalary?.averageAge,
            'totalNumberOfEmployee': _data?.averageSalary?.totalNumberOfEmployee,
            'totalAnnualSalary': _data?.averageSalary?.totalAnnualSalary,
            'coverTypes': _cover_type_list_data,
            'adminCharges': {
                'commission': _data?.adminCharges?.commission,
                'expense': _data?.adminCharges?.expense,
                'profit': _data?.adminCharges?.profit
            }
        }
        setIsLoading(true)
        const formattedPayload = formatGLAPayload(calculatePremiumPayload)
        try {
            const formattedPayload = formatGLAPayload(calculatePremiumPayload)
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': 'hgjku78uo8-cte736565r7656x4538uip80v-0-ty47536',
                },
                body: JSON.stringify(formattedPayload),
            });
            if (response.ok) {
                const result = await response.json();
                setMonthlyPremium(result?.totalMonthlyPremium)
                setAnnualPremium(result?.totalAnnualPremium)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                throw new Error('An error occurred while making the POST request:');
            }
        } catch (error) {
            setIsLoading(false)
            console.error('An error occurred while making the POST request:', error);
            throw error;

        }
    };

    const generateQuotes = async () => {
        const _data = JSON.parse(localStorage.getItem('GLACalculatorData'));
        const _cover_type_list_data = JSON.parse(localStorage.getItem('coverTypeListData'));
        const _complementary_benefits_list_data = JSON.parse(localStorage.getItem('complimentaryBenefitListData'));
        const url = process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payment.oldmutual.com.gh/api/v1/generate-quote' : 'https://test-payment.oldmutual.com.gh/api/v1/generate-quote';


        const calculatePremiumPayload = {
            'averageAge': _data?.averageSalary?.averageAge,
            'totalNumberOfEmployee': _data?.averageSalary?.totalNumberOfEmployee,
            'totalAnnualSalary': _data?.averageSalary?.totalAnnualSalary,
            'coverTypes': _cover_type_list_data,
            'adminCharges': {
                'commission': _data?.adminCharges?.commission,
                'expense': _data?.adminCharges?.expense,
                'profit': _data?.adminCharges?.profit
            },
            'complementaryCovers': _complementary_benefits_list_data,
            'discount': discount
        }
        setIsDownloading(true)
        const formattedPayload = formatGLAPayload(calculatePremiumPayload)
        try {
            const formattedPayload = formatGLAPayload(calculatePremiumPayload)
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': 'hgjku78uo8-cte736565r7656x4538uip80v-0-ty47536',
                },
                body: JSON.stringify(formattedPayload),
            });

            if (response.ok) {
                setIsDownloading(false)
                return await response.blob()
            } else {
                setIsDownloading(false)
                throw new Error('An error occurred while making the document download request:');
            }


        } catch (error) {
            setIsLoading(false)
            console.error('An error occurred while making the POST request:', error);
            throw error;
        }
    };

    const handleDownloadFile = async () => {
        try {
            const fileData = await generateQuotes();
            const blob = new Blob([fileData], { type: 'application/octet-stream' });
            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = 'QUOTE SUMMARY.pdf';
            a.click();
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error downloading file:', error);
            throw error;
        }
    };

    const handleChange = (e, label) => {
        const value = e.detail;
        const name = e.target.name;
        updateGLACalculatorData({ [name]: value }, label);
    };

    const uniqueOptions = () => {
        return COVER_TYPES.filter(cover =>
            !coverTypeListData.some(({ coverType }) => coverType === cover.fullcode)
        ).map((option) => ({
            value: option.description,
            label: option.fullcode,
        }))
    }

    const calculateDiscount = (discount, actualAmount) => {
        return (actualAmount - (discount / 100 * actualAmount)).toFixed(2);
    }

    const getMultipleLabel = (key) => {
        switch (key) {
        case 'MEDICAL COVER':
            return 'Multiple in Percentage (%)'
        case 'CRITICAL ILLNESS':
            return 'Multiple in Percentage (%)'
        case 'HOSPITAL CASH':
            return 'Salary Multiple'
        case 'TEMP DISABILITY':
            return 'Salary Multiple'
        case 'ACC DEATH':
            return 'Salary Multiple'
        case 'PERM DISABILITY':
            return 'Salary Multiple'
        default:
            return 'Multiple'
        }
    }

    const clearAll = () => {
        if (typeof window !== 'undefined') {
            localStorage.clear();
            navigate('/gla-calculator')
        }
    };

    return (
        <div className="gla-main-page">

            {/* Average Salary Section */}
            <div className="gla-main-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">
                    Average Salary Calculation
                </h5>
                <br />

                <OmFormInputFieldWrapper
                    label="Average Age of Employees"
                    id="averageAge"
                    name="averageAge"
                    labelAlwaysShow={true}
                    isSensitive
                    required
                    formId="mainForm"
                    size="full"
                    align="left"
                    type="text"
                    placeholder="e.g. 23"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={GLACalculatorData?.averageSalary?.averageAge}
                    state={isInputValid(GLACalculatorData?.averageSalary?.averageAge)}
                    errorMessage={
                        isInputValid(GLACalculatorData?.averageSalary?.averageAge) === 'error'
                            ? 'Please enter average age'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e, 'averageSalary')}
                />

                <OmFormInputFieldWrapper
                    label="Number of Employees to be insured"
                    id="totalNumberOfEmployee"
                    name="totalNumberOfEmployee"
                    labelAlwaysShow={true}
                    isSensitive
                    required
                    formId="mainForm"
                    size="full"
                    align="left"
                    type="text"
                    placeholder="e.g. 24"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={GLACalculatorData?.averageSalary?.totalNumberOfEmployee}
                    state={isInputValid(GLACalculatorData?.averageSalary?.totalNumberOfEmployee)}
                    errorMessage={
                        isInputValid(GLACalculatorData?.averageSalary?.totalNumberOfEmployee) === 'error'
                            ? 'Please enter total number of employee'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e, 'averageSalary')}
                />

                <OmFormInputFieldWrapper
                    label="Total Annual Salary"
                    id="totalAnnualSalary"
                    name="totalAnnualSalary"
                    labelAlwaysShow={true}
                    isSensitive
                    required
                    formId="mainForm"
                    size="full"
                    align="left"
                    type="text"
                    placeholder="e.g. 23"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={GLACalculatorData?.averageSalary?.totalAnnualSalary}
                    state={isInputValid(GLACalculatorData?.averageSalary?.totalAnnualSalary)}
                    errorMessage={
                        isInputValid(GLACalculatorData?.averageSalary?.totalAnnualSalary) === 'error'
                            ? 'Please enter total annual salary'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e, 'averageSalary')}
                />
            </div>


            {/* Sum Assured Calculation */}
            <div className="gla-main-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">
                    Sum Assured Calculation
                </h5>
                <br />
                {/* MAPPED TABLE */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', }}>
                    {/* GLA index 0 */}
                    <div>
                        <OmdsGrid>
                            <OmdsCell span="row" span-md="6">
                                <OmFormInputFieldWrapper
                                    label="GROUP LIFE ASSURANCE (GLA)"
                                    id="coverType"
                                    name="coverType"
                                    labelAlwaysShow={true}
                                    isSensitive
                                    className="inline-field"
                                    disabled
                                    required
                                    formId="mainForm"
                                    size="full"
                                    align="left"
                                    type="text"
                                    placeholder="GROUP LIFE ASSURANCE (GLA)"
                                    validationDelay={DEFAULT_VALIDATION_DELAY}
                                    value={coverTypeListData[0]?.coverType}
                                    state={isInputValid(
                                        coverTypeListData[0]?.coverType
                                    )}
                                    errorMessage={
                                        isInputValid(coverTypeListData[0]?.coverType) ===
                                            'error'
                                            ? 'Please select cover type'
                                            : undefined
                                    }
                                    onPersist={(e) => updateCoverTypeList(0, 'coverType', e.detail)}
                                />
                            </OmdsCell>
                            <OmdsCell span="row" span-md="7-11">
                                <OmFormInputFieldWrapper
                                    label="Multiple"
                                    id="maas"
                                    name="maas"
                                    labelAlwaysShow={true}
                                    isSensitive
                                    required
                                    formId="mainForm"
                                    size="full"
                                    align="left"
                                    type="text"
                                    placeholder="e.g. 3"
                                    validationDelay={DEFAULT_VALIDATION_DELAY}
                                    value={coverTypeListData[0]?.maas}
                                    state={isInputValid(coverTypeListData[0]?.maas) && isMutipleRangeValid(coverTypeListData[0]?.maas)}
                                    errorMessage={
                                        isInputValid(coverTypeListData[0]?.maas) === 'error' || isMutipleRangeValid(coverTypeListData[0]?.maas) === 'error'
                                            ? 'A lower limit of 1 and a maximum limit of 8 should apply'
                                            : undefined
                                    }

                                    onPersist={(e) => updateCoverTypeList(0, e.target.name, e.detail)}
                                />
                            </OmdsCell>
                            <OmdsCell span="row" span-md="12..">
                            </OmdsCell>
                        </OmdsGrid>
                    </div>

                    {/* Others index 1-6 */}
                    {coverTypeListData && coverTypeListData.filter((type) => type.coverType !== 'GLA').map((item, index) => (
                        <div key={index + 1}>
                            <OmdsGrid>
                                <OmdsCell span="row" span-md="6">
                                    <OmFormDropdownFieldWrapper
                                        id="coverType"
                                        name="coverType"
                                        useNativeMobileDropdown
                                        className="inline-field"
                                        required
                                        formId="mainForm"
                                        size="full"
                                        align="left"
                                        placeholder="Select Preferred Package"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={item?.coverType}
                                        state={isInputValid(
                                            item?.coverType
                                        )}
                                        errorMessage={
                                            isInputValid(item?.coverType) ===
                                                'error'
                                                ? 'Please select cover type'
                                                : undefined
                                        }
                                        options={JSON.stringify(
                                            uniqueOptions()
                                        )}
                                        onOnSelect={(e) => updateCoverTypeList(index + 1, 'coverType', e.detail.value)}
                                    />
                                </OmdsCell>
                                <OmdsCell span="row" span-md="7-11">

                                    {
                                        item?.coverType === 'HOSPITAL CASH'
                                            ?
                                            <OmFormDropdownFieldWrapper
                                                id="maas"
                                                name="maas"
                                                useNativeMobileDropdown
                                                className="inline-field"
                                                required
                                                formId="mainForm"
                                                size="full"
                                                align="left"
                                                placeholder="Salary Multiple"
                                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                                value={item.maas}
                                                state={isInputValid(item.maas)}
                                                errorMessage={
                                                    isInputValid(item.maas) === 'error'
                                                        ? 'Please enter Multiple'
                                                        : undefined
                                                }

                                                options={JSON.stringify(
                                                    HOSPITAL_CASH.map((option) => ({
                                                        value: option.description,
                                                        label: option.fullcode,
                                                    })
                                                    ))}
                                                onOnSelect={(e) => updateCoverTypeList(index + 1, 'maas', e.detail.value)}
                                            />

                                            :
                                            (item?.coverType === "WORKMEN'S COMPENSATION" || item?.coverType === "WORKMEN'S COMPENSATION WITH MEDICAL EXTENSION"

                                                ?
                                                <OmFormInputFieldWrapper
                                                    label={getMultipleLabel(item?.coverType)}
                                                    id="maas"
                                                    name="maas"
                                                    labelAlwaysShow={true}
                                                    isSensitive
                                                    required
                                                    formId="mainForm"
                                                    size="full"
                                                    align="left"
                                                    type="text"
                                                    keyboardMode="numeric"
                                                    placeholder="1"
                                                    validationDelay={DEFAULT_VALIDATION_DELAY}
                                                    // value={item.maas}
                                                    value={1}
                                                    state={isInputValid(item.maas)}
                                                    errorMessage={
                                                        isInputValid(item.maas) === 'error'
                                                            ? 'note that this should be non-editable'
                                                            : undefined
                                                    }
                                                    onPersist={(e) => updateCoverTypeList(index + 1, e.target.name, 1)}
                                                />

                                                :

                                                (item?.coverType === 'MEDICAL COVER'

                                                    ?

                                                    <OmFormInputFieldWrapper
                                                        label={getMultipleLabel(item?.coverType)}
                                                        id="maas"
                                                        name="maas"
                                                        labelAlwaysShow={true}
                                                        isSensitive
                                                        required
                                                        formId="mainForm"
                                                        size="full"
                                                        align="left"
                                                        type="text"
                                                        placeholder="e.g. 3"
                                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                                        value={item?.maas}
                                                        state={isInputValid(item?.maas)}
                                                        errorMessage={
                                                            isInputValid(item.maas) === 'error'
                                                                ? 'Please enter mutiple in Percentage(%)'
                                                                : undefined
                                                        }
                                                        onPersist={(e) => updateCoverTypeList(index + 1, e.target.name, e.detail)}
                                                    />

                                                    :

                                                    <OmFormInputFieldWrapper
                                                        label={getMultipleLabel(item?.coverType)}
                                                        id="maas"
                                                        name="maas"
                                                        labelAlwaysShow={true}
                                                        isSensitive
                                                        required
                                                        formId="mainForm"
                                                        size="full"
                                                        align="left"
                                                        type="text"
                                                        placeholder="e.g. 3"
                                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                                        value={item?.maas}
                                                        state={isInputValid(item?.maas) && isMutipleRangeValid(item?.maas)}
                                                        errorMessage={
                                                            isInputValid(item.maas) === 'error' || isMutipleRangeValid(item?.maas) === 'error'
                                                                ? 'A lower limit of 1 and a maximum limit of 8 should apply'
                                                                : undefined
                                                        }
                                                        onPersist={(e) => updateCoverTypeList(index + 1, e.target.name, e.detail)}
                                                    />)


                                            )
                                    }

                                </OmdsCell>
                                <OmdsCell span="row" span-md="12.." >
                                    <OmdsIcon onClick={() => removeCoverTypeFromList(index + 1)} size={24} name="trash" />
                                </OmdsCell>
                            </OmdsGrid>
                        </div>
                    ))}
                </div>

                {/* package adding trigger */}
                <div style={{ marginTop: '2rem' }}>
                    {coverTypeListData.length < 9 && <OmApplicationButton
                        text="ADD ANOTHER PACKAGE"
                        onButtonClicked={() => addNewCoverTypeToList()}
                    />}
                </div>

            </div>


            {/* Administrative Charges Calculations */}
            <div className="gla-main-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">
                    Administrative Charges Calculation
                </h5>
                <br />

                <OmFormInputFieldWrapper
                    label="Commission (%)"
                    id="commission"
                    name="commission"
                    labelAlwaysShow={true}
                    isSensitive
                    required
                    formId="mainForm"
                    size="full"
                    align="left"
                    type="text"
                    placeholder="e.g. 10"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={GLACalculatorData?.adminCharges?.commission}
                    state={isInputValid(GLACalculatorData?.adminCharges?.commission)}
                    errorMessage={
                        isInputValid(GLACalculatorData?.adminCharges?.commission) === 'error'
                            ? 'Please enter commission'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e, 'adminCharges')}
                />

                <OmFormInputFieldWrapper
                    label="Expense (%)"
                    id="expense"
                    name="expense"
                    labelAlwaysShow={true}
                    isSensitive
                    required
                    formId="mainForm"
                    size="full"
                    align="left"
                    type="text"
                    placeholder="e.g. 10"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={GLACalculatorData?.adminCharges?.expense}
                    state={isInputValid(GLACalculatorData?.adminCharges?.expense)}
                    errorMessage={
                        isInputValid(GLACalculatorData?.adminCharges?.expense) === 'error'
                            ? 'Please enter expense'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e, 'adminCharges')}
                />
                <OmFormInputFieldWrapper
                    label="Profit (%)"
                    id="profit"
                    name="profit"
                    labelAlwaysShow={true}
                    isSensitive
                    required
                    formId="mainForm"
                    size="full"
                    align="left"
                    type="text"
                    placeholder="e.g. 10"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={GLACalculatorData?.adminCharges?.profit}
                    state={isInputValid(GLACalculatorData?.adminCharges?.profit)}
                    errorMessage={
                        isInputValid(GLACalculatorData?.adminCharges?.profit) === 'error'
                            ? 'Please enter profit'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e, 'adminCharges')}
                />


                {/* 1st Trigger -> calculate premium button */}
                <div style={{ marginTop: '2rem' }}>
                    <OmButton
                        text={isLoading ? 'SUBMITTING...' : 'CALCULATE PREMIUM'}
                        iconName="arrow_forward"
                        slot="right"
                        onClick={() => calculatePremium()}
                        type="primary"
                        size="small"
                    />
                </div>


                {/* Discount and Premium Amount */}
                <div style={{ marginTop: '2rem' }}>
                    <div className='quote-wrapper'>
                        <div className='quote-header'>
                            <p>QUOTE SUMMARY</p>
                            {!discountFieldOpen ?
                                <div>
                                    <OmApplicationButton
                                        text="ADD DISCOUNT"
                                        onButtonClicked={() => openDiscountField()}
                                    />
                                </div>
                                :
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '4px' }}>
                                    <OmButton
                                        text="APPLY DISCOUNT"
                                        iconName="arrow_forward"
                                        slot="right"
                                        onClick={() => applyDiscount()}
                                        type="primary"
                                        size="small"
                                    />
                                    <OmApplicationButton
                                        text="CANCEL"
                                        onButtonClicked={() => closeDiscountField()}
                                    />
                                </div>
                            }

                        </div>
                        {/* discount input field */}
                        {discountFieldOpen && <div style={{ margin: '2rem 0' }}>
                            <OmFormInputFieldWrapper
                                label="Discount (%)"
                                id="discount"
                                name="discount"
                                labelAlwaysShow={true}
                                isSensitive
                                required
                                formId="mainForm"
                                size="full"
                                align="left"
                                type="text"
                                keyboardMode="numeric"
                                placeholder="e.g. 10"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={discount}
                                state={isInputValid(discount)}
                                errorMessage={
                                    isInputValid(discount) === 'error'
                                        ? 'Please enter discount'
                                        : undefined
                                }
                                onPersist={(e) => updateDiscount(e.detail)}
                            />
                        </div>}

                        {/* discount quotation */}
                        {
                            discount &&
                            <div>
                                <p style={{ marginBottom: '1px' }}>{`DISCOUNTED AMOUNT (${discount}%)`}</p>
                                <div className='quote-content'>
                                    <div className='quote-summary-right'>
                                        <p><strong>{`₵${calculateDiscount(discount, annualPremium)}`}</strong></p>
                                        <p>TOTAL ANNUAL PREMIUM</p>
                                    </div>
                                    <div className='quote-summary-left'>
                                        <p><strong>{`₵${calculateDiscount(discount, monthlyPremium)}`}</strong></p>
                                        <p>TOTAL MONTHLY PREMIUM</p>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* initial quotation */}
                        {annualPremium && monthlyPremium && <div style={{ margin: '1rem 0' }}>
                            {discount && <p style={{ marginBottom: '2px' }}>INITIAL AMOUNT</p>}
                            <div className='quote-content'>
                                <div className='quote-summary-right'>
                                    <p><strong>{`₵${Number(annualPremium)?.toFixed(2)}`}</strong></p>
                                    <p>TOTAL ANNUAL PREMIUM</p>
                                </div>
                                <div className='quote-summary-left'>
                                    <p><strong>{`₵${Number(monthlyPremium).toFixed(2)}`}</strong></p>
                                    <p>TOTAL MONTHLY PREMIUM</p>
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>


            {/* Complimentary Benefits Added */}
            <div className="gla-main-page-wrapper">
                <div className='quote-header' style={{ margin: '2rem 0' }}>
                    {complimentaryBenefitListData.length > 0 ?
                        <>
                            <h5 className="heading medium" id="heading-anchor">
                                Complimentary Benefits Added
                            </h5>
                            <div>
                                <OmApplicationButton
                                    text="EDIT BENEFITS"
                                    onButtonClicked={() => toggleComplimentaryBenefitDrawer()}
                                />
                            </div>
                        </>
                        :
                        <OmApplicationButton
                            text="ADD COMPLIMENTARY BENEFITS"
                            onButtonClicked={() => toggleComplimentaryBenefitDrawer()}
                        />
                    }

                </div>
                <ComplimentaryBenefitSideDrawer />
            </div>

            {/* get quote and Download PDF*/}
            <div className="gla-main-page-wrapper">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '2rem' }}>

                    <OmApplicationButton
                        text="CLEAR ALL"
                        onButtonClicked={() => clearAll()}
                    />
                    <OmButton
                        text={isDownloading ? 'DOWNLOADING...' : 'DOWNLOAD PDF'}
                        iconName="arrow_forward"
                        slot="right"
                        onClick={() => handleDownloadFile()}
                        type="primary"
                        size="small"
                    />
                </div>
            </div>
        </div>
    );
};

export default MainPage;

