const GLA_CALCULATION_LEVEL = {
    AverageSalaryCalculation: 'Average Salary Calculation',
    SumAssuredCalculation: 'Sum Assured Calculation',
    AdministrativeChargesCalculation: 'Administrative Charges Calculation',
    DiscountCalculation: 'Discount Calculation',
    ComplimentaryBenefitsAdded: 'Complimentary Benefits Added',

}

const COVER_TYPES = [
    { fullcode: 'GLA', description: 'Group Life Assurance' },
    { fullcode: 'ACC DEATH', description: 'Accidental Death' },
    { fullcode: 'TEMP DISABILITY', description: 'Temporary Disability' },
    { fullcode: 'PERM DISABILITY', description: 'Permanent Disability' },
    { fullcode: 'MEDICAL COVER', description: 'Medical Cover' },
    { fullcode: 'CRITICAL ILLNESS', description: 'Critical Illness' },
    {
        fullcode: "WORKMEN'S COMPENSATION",
        description: "workmen's compensation"
    },
    {
        fullcode: "WORKMEN'S COMPENSATION WITH MEDICAL EXTENSION",
        description: "workmen's compensation with medical extension"
    },
    { fullcode: 'HOSPITAL CASH', description: 'Hospital Cash' }
];

const HOSPITAL_CASH = [
    { fullcode: '50', description: '50' },
    { fullcode: '100', description: '100' },
    { fullcode: '150', description: '150' },
    { fullcode: '200', description: '200' },
];
const COMPLIMENTARY_BENEFIT_TYPES = [
    { fullcode: 'CHILD COVER', description: 'Child Cover' },
    { fullcode: 'SPOUSAL COVER', description: 'Spousal Cover' },
    { fullcode: 'TRAVEL INSURANCE (30 DAYS)', description: 'Travel Insurance (30 days)' },
    { fullcode: 'HEALTH TALK', description: 'Health Talk' },
    { fullcode: 'MONTHLY NEWSLETTERS', description: 'Monthly Newsletters' },
    { fullcode: 'ANNUAL AEROBIC SESSIONS', description: 'Annual Aerobic Sessions' },
]

export {
    GLA_CALCULATION_LEVEL,
    COVER_TYPES,
    HOSPITAL_CASH,
    COMPLIMENTARY_BENEFIT_TYPES
};
