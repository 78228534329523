import React, { useContext, useState } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../ghana/configs/gla-calculator-configs/config';
import {
    OmFormInputFieldWrapper,
    OmFormDropdownFieldWrapper,
    OmApplicationBeneficiaryDetails,
    OmSideDrawer,
    OmApplicationButton
} from '@om/component-library-react';
import {
    OmdsCell,
    OmdsGrid, OmdsIcon
} from '@mom-omds/react-design-system-components/dist/generated/components';
import OmButton from '../../../../../../../components/forms/OmButton';
import { GLACalculatorContext } from '../../../glaCalculator/context/GLACalculatorContext';
import {
    isInputValid,
} from '../../../../../ghana/utils/gla-calculator-utils/util';
import { COMPLIMENTARY_BENEFIT_TYPES } from '../../constants/refeferenceData/glaCalculatorLevel';


const ComplimentaryBenefitSideDrawer = () => {
    const { complimentaryBenefitModalOpen, complimentaryBenefitListData, toggleComplimentaryBenefitDrawer, addNewComplimentaryBenefitToList, updateComplimentaryBenefitList, removeComplimentaryBenefitFromList, resetComplimentaryBenefitList } = useContext(GLACalculatorContext);

    const uniqueOptions = () => {
        return COMPLIMENTARY_BENEFIT_TYPES.filter(benefit =>
            !complimentaryBenefitListData.some(({ name }) => name === benefit.fullcode)
        ).map((option) => ({
            value: option.description,
            label: option.fullcode,
        }))
    }

    const getLabel = (key) => {
        switch (key) {
        case 'TRAVEL INSURANCE (30 DAYS)':
            return 'Enter Sum Assured (EUR)'
        default:
            return 'Enter Sum Assured (GH₵)'
        }
    }

    return (
        <OmSideDrawer
            onOnSideDrawerClosed={() => {
                toggleComplimentaryBenefitDrawer()
            }}
            open={complimentaryBenefitModalOpen}
        >
            <div slot="side-drawer-content">
                {/* <OmApplicationBeneficiaryDetails>
          
        </OmApplicationBeneficiaryDetails> */}
                <div slot="om-application-beneficiary-details">
                    <h5>
                        <strong>Complimentary Benefits </strong>
                    </h5>
                    <hr style={{ margin: '2rem 0' }} />

                    {/* MAPPED TABLE */}
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', }}>
                        {complimentaryBenefitListData.map((item, index) => (
                            <div key={index}>
                                <OmdsGrid>
                                    <OmdsCell span="row" span-md="6">
                                        <OmFormDropdownFieldWrapper
                                            id="name"
                                            name="name"
                                            useNativeMobileDropdown
                                            className="inline-field"
                                            required
                                            formId="mainForm"
                                            size="full"
                                            align="left"
                                            placeholder="Select Policy Type"
                                            validationDelay={DEFAULT_VALIDATION_DELAY}
                                            value={item?.name}
                                            state={isInputValid(
                                                item?.name
                                            )}
                                            errorMessage={
                                                isInputValid(item?.name) ===
                          'error'
                                                    ? 'Please select cover type'
                                                    : undefined
                                            }
                                            options={JSON.stringify(
                                                uniqueOptions()
                                            )}
                                            onOnSelect={(e) => updateComplimentaryBenefitList(index, 'name', e.detail.value)}
                                        />
                                    </OmdsCell>
                                
                                    <OmdsCell span="row" span-md="7-11">
                                        { COMPLIMENTARY_BENEFIT_TYPES.length > 0 && (item.name === COMPLIMENTARY_BENEFIT_TYPES[0].fullcode || item.name === COMPLIMENTARY_BENEFIT_TYPES[1].fullcode || item.name === COMPLIMENTARY_BENEFIT_TYPES[2].fullcode)
                                            ?
                                            <OmFormInputFieldWrapper
                                                label={getLabel(item?.name)}
                                                id="sumAssured"
                                                name="sumAssured"
                                                labelAlwaysShow={true}
                                                isSensitive
                                                required
                                                formId="mainForm"
                                                size="full"
                                                align="left"
                                                type="text"
                                                keyboardMode="numeric"
                                                placeholder="e.g. 3"
                                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                                value={item.sumAssured}
                                                state={isInputValid(item.sumAssured)}
                                                errorMessage={
                                                    isInputValid(item.sumAssured) === 'error'
                                                        ? 'Please enter Sum Assured'
                                                        : undefined
                                                }
                                                onPersist={(e) => updateComplimentaryBenefitList(index, e.target.name, e.detail)}
                                            />
                                            :
                                            <p className="om-form-input-field-wrapper input" style={{ textAlign: 'left', color: '#282828', marginBottom: '8px', padding: '8px 0', fontSize: '16px', lineHeight: '20px', border: 0, borderRadius: 0, width: '100%', borderBottom: '1px solid', }}>All Staff</p>
                                        }
                                    </OmdsCell>


                                    <OmdsCell span="row" span-md="12.." >
                                        <OmdsIcon onClick={() => removeComplimentaryBenefitFromList(index)} size={24} name="trash" />
                                    </OmdsCell>
                                </OmdsGrid>
                            </div>
                        ))}
                    </div>

                    {/* package adding trigger */}
                    <div style={{ marginTop: '2rem' }}>
                        {complimentaryBenefitListData.length < 6 && <OmApplicationButton
                            text="ADD ANOTHER BENEFIT"
                            onButtonClicked={() => addNewComplimentaryBenefitToList()}
                        />}
                    </div>



                    <div className="action-buttons" style={{ marginTop: '2rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <OmButton
                            className="cancel-button"
                            type="text"
                            size="small"
                            text="CLEAR ALL"
                            onClick={() => resetComplimentaryBenefitList()}
                        />

                        <OmButton
                            className="save-button"
                            type="primary"
                            size="small"
                            text="ADD BENEFITS"
                            onClick={() => toggleComplimentaryBenefitDrawer()}
                        />
                    </div>
                </div>
            </div>
        </OmSideDrawer>
    );
};

export default ComplimentaryBenefitSideDrawer;
